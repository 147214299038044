import React, { CSSProperties, useState, useCallback, memo, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { KeyboardShortcutScope, KeyboardShortcutScopes } from 'hooks/useKeyboardShortcuts';
import { useEventEmitterListener } from 'hooks/useEventEmitterListener';
import { EventEmitterTypes } from 'types/event-emitter.type';
import LayerType from 'components/template-designer/types/layer.type';
import Animation, {
    ActiveAnimationType,
    AnimationOptions,
    AnimationValue,
    PositionAnimation,
    RotationAnimation,
    SizeAnimation
} from 'components/template-designer/types/animation.type';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import Template, { DesignerSettings, RightSidebarTab, State, View } from 'components/template-designer/types/template.type';
import Format from 'components/template-designer/types/format.type';
import { generateKey } from 'components/template-designer/utils/generateKey';
import { ResizeOptions, TextProperties } from 'components/template-designer/types/layerProperties.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import cloneDeep from 'components/template-designer/utils/cloneDeep';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Translation from 'components/data/Translation';
import { TemplateVersionHelpers } from 'components/template-designer/helpers/template-version.helpers';
import LayerHelpers from 'components/template-designer/helpers/layer.helpers';
import { Guideline } from 'components/template-designer/types/formatProperties.type';
import TemplateDesignerStore, { MultiModel } from 'components/template-designer/data/template-designer-store';
import { TimelineHelpers } from 'components/template-designer/helpers/timeline.helpers';
import { LayerPropertiesHelpers } from 'components/template-designer/helpers/layer-properties.helpers';
import Layer from './layer';
import Editor from './editor';
import { NewPosition } from '../types/types';

interface Props {
    preview?: boolean;
    isBase?: boolean;
    format: Format;
    zoomLevel: number;
    customLayerProperties?: Template['layerProperties'];
    frameLayers: LayerType[];
    frameType: View['frameType'];
    customCSS: DesignerSettings['customCSS'];
    customJS: DesignerSettings['customJS'];
    formatPadding?: CSSProperties['padding'];
    showHover: boolean;
    selectLayer?: (layer: LayerType | null, formatKey: Format['key'] | null, openItemTree?: boolean) => void;
    layoutGrid: Guideline[] | undefined;
}

interface Data {
    selectedLayers: State['selectedLayers'];
    isPlaying: State['isPlaying'];
}

/* Renders all layers of a format. */
const LayerContainer = memo(
    ({
        preview,
        format,
        zoomLevel,
        customLayerProperties,
        frameLayers,
        frameType,
        customCSS,
        customJS,
        formatPadding,
        showHover,
        layoutGrid,
        selectLayer
    }: Props) => {
        const currentFrameType = useMemo(() => getTemplateData<View['frameType']>('view.frameType'), []);
        const { selectedLayers, isPlaying } = useComponentStore<Data>('TemplateDesigner', {
            fields: {
                selectedLayers: 'state.selectedLayers',
                isPlaying: 'state.isPlaying'
            }
        });

        const currentTime = useEventEmitterListener(EventEmitterTypes.TDcurrentTime) ?? 0;

        const [editingTextLayerKey, setEditingTextLayerKey] = useState<LayerType['key'] | null>(null);

        /* disable text-editing state of layer and save to store */
        const changeLayerText = useCallback(
            (value: TextProperties['text'], layerKey: LayerType['key']) => {
                const formats = getTemplateData<Template['formats']>('formats');
                const selectedFormats = getTemplateData<State['selectedFormats']>('state.selectedFormats');
                setEditingTextLayerKey(null);
                const generalStyle = (selectedFormats && selectedFormats.length === formats.length) || selectedFormats[0] === 'general';
                TemplateDesignerStore.save([`layerProperties.${generalStyle ? 'general' : format.key}.${frameType}.${layerKey}.properties.text`, value]);
            },
            [frameType]
        );

        /**
         * Update the layer in the store.
         * @param position - New position from Editor.
         * @param positionChanged - Is the position changed.
         * @param sizeChanged - Is the size changed.
         * @param rotationChanged - Is the rotation changed.
         */
        const updateLayer = (
            position: NewPosition['position'],
            positionChanged: NewPosition['positionChanged'],
            widthChanged: NewPosition['widthChanged'],
            heightChanged: NewPosition['heightChanged'],
            rotationChanged: NewPosition['rotationChanged'],
            format?: Format
        ): void => {
            const newPosition = {
                ...position,
                x: {
                    value: position.x,
                    unit: position.xUnit
                },
                y: {
                    value: position.y,
                    unit: position.yUnit
                },
                width: {
                    value: position.width,
                    unit: position.widthUnit
                },
                height: {
                    value: position.height,
                    unit: position.heightUnit
                }
            };

            const formats = getTemplateData<Template['formats']>('formats');

            const layerKey = selectedLayers?.[0].key;
            const formatKey = (() => {
                if (formats.length === 1) return 'general';
                if (format) return format.key;
                return 'general';
            })();

            if (!layerKey || !formatKey) return;

            const layerProperties = getTemplateData<Template['layerProperties']>('layerProperties');

            const { layerProps } = LayerPropertiesHelpers.mergeLayerProperties(layerKey, frameType, [formatKey], layerProperties);
            const animations = cloneDeep(layerProps.animations ?? {});
            const changes: MultiModel = [];

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            function addKeyframe(key: AnimationOptions, value: any, currentTimestamp: Animation['stamp']) {
                const newTimestamp: AnimationValue = {
                    value,
                    stamp: currentTimestamp,
                    id: generateKey(),
                    easing: {
                        type: 'linear',
                        value: 'linear'
                    }
                };

                if (!animations[key]) animations[key] = [];
                animations[key].push(newTimestamp);
                animations[key].sort((a, b) => a.stamp - b.stamp);

                changes.push(
                    [`state.activeAnimations`, [{ type: ActiveAnimationType.Keyframe, keyframeKey: newTimestamp.id, animationKey: key, layer: layerKey }]],
                    ['view.showTimeline', true],
                    ['view.showTab', RightSidebarTab.Animate]
                );

                const format = formats.find((f) => f.key === formatKey);

                SnackbarUtils.info(
                    Translation.get('canvas.keyframes.keyframeAdded', 'template-designer', {
                        type: Translation.get(`timeline.animationOptions.${key}`, 'template-designer'),
                        format: format?.title ?? ''
                    })
                );
            }

            // Set position keyframe if position is animated.
            if (positionChanged && animations?.position?.length > 0) {
                const keyframe = TimelineHelpers.getKeyframeByCurrentTime(animations.position) as PositionAnimation;

                if (keyframe) {
                    keyframe.value.x.value = newPosition.x.value as number;
                    keyframe.value.y.value = newPosition.y.value as number;
                } else {
                    const currentStamp = TimelineHelpers.getCurrentStamp();
                    addKeyframe('position', { x: newPosition.x, y: newPosition.y }, currentStamp ?? 0);
                }
            }

            // Set size keyframe if size is animated.
            if ((widthChanged || heightChanged) && animations?.size?.length > 0) {
                const keyframe = TimelineHelpers.getKeyframeByCurrentTime(animations.size) as SizeAnimation;

                if (keyframe) {
                    keyframe.value.width.value = newPosition.width.value as number;
                    keyframe.value.height.value = newPosition.height.value as number;
                } else {
                    const currentStamp = TimelineHelpers.getCurrentStamp();
                    addKeyframe('size', { width: newPosition.width, height: newPosition.height }, currentStamp ?? 0);
                }
            }

            // Set rotation keyframe if rotation is animated.
            if (rotationChanged && animations?.rotation?.length > 0) {
                const keyframe = TimelineHelpers.getKeyframeByCurrentTime(animations.rotation) as RotationAnimation;

                if (keyframe) {
                    keyframe.value = {
                        value: newPosition.rotation,
                        transformOrigin: { xOffset: 50, yOffset: 50 }
                    };
                } else {
                    const currentStamp = TimelineHelpers.getCurrentStamp();
                    addKeyframe('rotation', { value: newPosition.rotation, transformOrigin: { xOffset: 50, yOffset: 50 } }, currentStamp ?? 0);
                }
            }

            if (positionChanged && animations?.position?.length > 0) {
                changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.animations.position`, animations.position]);
            }

            if ((widthChanged || heightChanged) && animations?.size?.length > 0) {
                changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.animations.size`, animations.size]);
            }

            if (rotationChanged && animations?.rotation?.length > 0) {
                changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.animations.rotation`, animations.rotation]);
            }

            // Update the layer properties (if there are no animation keyframes).
            if (positionChanged && (!animations.position || animations?.position?.length === 0)) {
                changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.horizontalAlign`, null]);
                changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.verticalAlign`, null]);
                changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.x`, newPosition.x]);
                changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.y`, newPosition.y]);
                changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.position`, newPosition.position]);
            }
            if ((widthChanged || heightChanged) && (!animations.size || animations?.size?.length === 0)) {
                if (widthChanged)
                    changes.push([
                        `layerProperties.${formatKey}.${frameType}.${layerKey}.properties.width`,
                        { ...newPosition.width, resize: ResizeOptions.Fixed }
                    ]);
                if (heightChanged)
                    changes.push([
                        `layerProperties.${formatKey}.${frameType}.${layerKey}.properties.height`,
                        { ...newPosition.height, resize: ResizeOptions.Fixed }
                    ]);
            }
            if (rotationChanged && (!animations.rotation || animations?.rotation?.length === 0)) {
                changes.push([`layerProperties.${formatKey}.${frameType}.${layerKey}.properties.rotation`, newPosition.rotation]);
            }

            if (formats.length > 1) {
                changes.push(['state.selectedFormats', [formatKey]]);
            }

            TemplateDesignerStore.save(changes);
        };

        /**
         * Find the layer below the clicked layer.
         * @param clickPosition - The position of the click.
         * @returns The layer below the clicked layer or null when it can't find it.
         */
        const findLayerBelow = useCallback((clickPosition: { x: number; y: number }): LayerType | null => {
            // Get element that is clicked on.
            const currentElement = document.elementFromPoint(clickPosition.x, clickPosition.y) as HTMLElement;
            if (!currentElement) return null;
            const originalDisplay = currentElement.style.display;
            currentElement.style.display = 'none';
            // Get element below the clicked element.
            const elementBelow = document.elementFromPoint(clickPosition.x, clickPosition.y) as HTMLElement;
            if (!elementBelow) {
                currentElement.style.display = originalDisplay;
                return null;
            }
            currentElement.style.display = originalDisplay;
            const layerKey = elementBelow.dataset.layerKey ?? null;
            if (!layerKey) return null;
            const layer = LayerHelpers.findLayer(layerKey);
            return layer;
        }, []);

        /**
         * Use the clicked on layer to determine which layer to select.
         * Wrapped in debounce to prevent single click from also firing when double clicking.
         * @param event - Mouse event.
         * @param doubleClick - Is the click a double click.
         */
        const findLayerToSelect = useCallback(
            debounce((layerKey: string, ctrlClick: boolean, doubleClick?: boolean, clickPosition?: { x: number; y: number }): void => {
                if (!selectLayer) return;

                const clickedLayer = LayerHelpers.findLayer(layerKey);
                const currentSelectedLayer = selectedLayers[0];
                if (!clickedLayer) return;

                const layerPath = LayerHelpers.findLayerPath(frameLayers, layerKey);

                if (!layerPath) return;

                const layerKeysInPath = layerPath.split('.');

                if (ctrlClick) {
                    if (clickedLayer.locked) {
                        if (!clickPosition) return;
                        const layerBelow = findLayerBelow(clickPosition);
                        if (!layerBelow) return;
                        return selectLayer(layerBelow, format.key, true);
                    }

                    return selectLayer(clickedLayer, format.key, true);
                }

                if (doubleClick) {
                    // If the layer is a text layer and is already selected, start editing the text.
                    if (currentSelectedLayer?.type === 'text' && currentSelectedLayer.key === clickedLayer.key) {
                        if (clickedLayer.locked) return;
                        const selectedFormat = getTemplateData<State['selectedFormats']>('state.selectedFormats')[0] ?? 'general';
                        if (selectedFormat !== 'general' && selectedFormat !== format.key) return;
                        return setEditingTextLayerKey(layerKey);
                    }

                    if (!layerKeysInPath) return;

                    /**
                     * Select the next layer in the layer path.
                     * @param layerKeys - The layer keys from the layer path.
                     * @param currentIndex - The index of the current selected layer in the layer path.
                     * @param formatKey - The format key.
                     */
                    const selectNextLayer = (layerKeys: string[], currentIndex: number): LayerType | null => {
                        const layerKeyToSelect = layerKeys?.[currentIndex + 1];
                        if (!layerKeyToSelect) return null;
                        const layerToSelect = LayerHelpers.findLayer(layerKeyToSelect);
                        if (!layerToSelect || layerToSelect.locked) return null;
                        return layerToSelect;
                    };

                    if (layerKeysInPath.length === 1) {
                        if (clickedLayer.locked) return;
                        return selectLayer(clickedLayer, format.key, true);
                    }

                    // If there is no selected layer, check if there is a layer inside the path.
                    if (!currentSelectedLayer && layerKeysInPath[1]) {
                        const layerToSelect = LayerHelpers.findLayer(layerKeysInPath[1]);
                        if (!layerToSelect) return;
                        return selectLayer(layerToSelect, format.key, true);
                    }

                    // If the selected layer is in the layer path, select the next layer in the path.
                    const currentIndex = currentSelectedLayer && layerKeysInPath.findIndex((key) => key === currentSelectedLayer.key);
                    if (currentIndex !== -1) {
                        const layer = selectNextLayer(layerKeysInPath, currentIndex);
                        if (!layer) return;
                        return selectLayer(layer, format.key, true);
                    }
                }

                // If there is no selected layer, click the top most layer or the clicked layer.
                if (!currentSelectedLayer) {
                    if (!layerPath) return;

                    const layerToSelect = LayerHelpers.findLayer(layerKeysInPath[0]);

                    if (!layerToSelect) return;

                    // Check if the layer below can be selected.
                    if (layerToSelect.locked) {
                        if (!clickPosition) return;
                        const layerBelow = findLayerBelow(clickPosition);
                        if (!layerBelow) return;

                        // Check if layer below is a child of the clicked layer. Then don't select it because that layer is then also locked by it's parent.
                        const isChild = LayerHelpers.isChildLayer(layerToSelect, layerBelow);
                        if (isChild) return;

                        // Get top most layer to select.
                        const topParentLayer = LayerHelpers.findTopLayerByKey(frameLayers, layerBelow.key);
                        if (!topParentLayer) return;

                        return selectLayer(topParentLayer, format.key);
                    }

                    return selectLayer(layerToSelect, format.key);
                }

                // If the clicked layer is the same as the selected layer and the selected layer is locked, deselect the layer.
                if (currentSelectedLayer.key === clickedLayer.key && clickedLayer.locked) {
                    return LayerHelpers.deselectLayer(currentSelectedLayer.key);
                }

                const firstParentSelectedLayer = LayerHelpers.findFirstParentByKey(frameLayers, currentSelectedLayer.key);
                const firstParentClickedLayer = LayerHelpers.findFirstParentByKey(frameLayers, clickedLayer.key);

                // If the selected layer is in the same level as the clicked layer. Select the clicked layer.
                if (firstParentSelectedLayer?.key === firstParentClickedLayer?.key) {
                    if (clickedLayer.locked) {
                        if (!clickPosition) return;
                        const layerBelow = findLayerBelow(clickPosition);
                        if (!layerBelow) return;
                        return selectLayer(layerBelow, format.key);
                    }
                    return selectLayer(clickedLayer, format.key);
                }

                const parentClickedLayer = LayerHelpers.findTopLayerByKey(frameLayers, clickedLayer.key);
                if (parentClickedLayer?.key === currentSelectedLayer.key) {
                    if (currentSelectedLayer.locked) return LayerHelpers.deselectLayer(currentSelectedLayer.key);
                    return selectLayer(currentSelectedLayer, format.key);
                }

                // Selected layer is not in the same level as the clicked layer. Select the parent of the clicked layer.
                if (!parentClickedLayer || parentClickedLayer.locked) return;
                selectLayer(parentClickedLayer, format.key);
            }, 200),
            [selectLayer, selectedLayers, frameLayers, format.key]
        );

        const activeLayer = selectedLayers[0];
        const showEditor = !!(activeLayer && !isPlaying && activeLayer.type !== 'audio' && !editingTextLayerKey && !preview && currentFrameType === frameType);
        const layersShouldNotReverse = useMemo(() => TemplateVersionHelpers.layersShouldNotReverse(), []);

        return (
            <>
                <div style={{ padding: formatPadding }} className="layers-container">
                    <div
                        data-cy={`templateDesigner-canvasFormatLayersContainer-div`}
                        className="layers-group"
                        onClick={(event) => {
                            event.stopPropagation();
                            KeyboardShortcutScope.setScope(KeyboardShortcutScopes.TDEditor);
                            const target = event.target as HTMLElement;
                            const layerKey = target.dataset.layerKey ?? null;
                            if (!layerKey) return;
                            const ctrlClick = event.ctrlKey || event.metaKey;
                            const doubleClick = event.detail === 2;
                            const x = event.clientX;
                            const y = event.clientY;
                            findLayerToSelect(layerKey, ctrlClick, doubleClick, { x, y });
                        }}>
                        {frameLayers.map((layer, i) => (
                            <Layer
                                dataCyPrefix="templateDesigner-displayLayerContainer"
                                key={layer.key}
                                frameType={frameType}
                                customLayerProperties={customLayerProperties}
                                layer={layer}
                                changeLayerText={changeLayerText}
                                editingTextLayerKey={editingTextLayerKey}
                                format={format}
                                showHover={showHover}
                                customCSS={customCSS}
                                customJS={customJS}
                                totalLayers={frameLayers.length}
                                currentLayerIndex={i}
                                layersShouldReverse={!layersShouldNotReverse}
                                preview={preview}
                            />
                        ))}
                    </div>
                </div>

                {showEditor && (
                    <Editor
                        layer={activeLayer}
                        frameType={frameType}
                        format={format}
                        zoomLevel={zoomLevel}
                        onClickLayer={findLayerToSelect}
                        saveLayer={updateLayer}
                        layoutGrid={layoutGrid}
                        layersShouldReverse={!layersShouldNotReverse}
                        currentTime={currentTime}
                    />
                )}
            </>
        );
    }
);

LayerContainer.displayName = 'LayerContainer';

export default LayerContainer;
