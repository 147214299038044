import React, { useEffect, useRef } from 'react';
import Format from 'types/format.type';
import { SetupSafeZoneResource, SafeZone as SafeZoneType } from 'types/resources/setup_safeZone.type';
import { SafeZone as FormatStylingSafeZone } from 'components/template-designer/types/formatProperties.type';
import FrameType from 'components/template-designer/types/frameTypes.type';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { useResources } from 'components/data/Resources/hooks/useResources';
import { TemplateData, View } from 'components/template-designer/types/template.type';
import defaultSafeZone from 'components/template-designer/config/defaultSafeZone';
import '../styles/safe-zone.scss';

interface Props {
    format: Format;
    frameType: FrameType['key'];
}

interface Data {
    showSafeZones: View['showSafeZones'];
    formatSafeZoneLayout: FormatStylingSafeZone['activeLayout'];
    generalSafeZoneLayout: FormatStylingSafeZone['activeLayout'];
    formatSafeZoneColor: FormatStylingSafeZone['color'];
    generalSafeZoneColor: FormatStylingSafeZone['color'];
    type: TemplateData['type'];
}

const SAFE_ZONE_RESOLUTION_SCALING = 3;

const SafeZone = ({ format, frameType }: Props): JSX.Element | null => {
    const { resources } = useResources<{ setup_safeZone: SetupSafeZoneResource }>(['setup_safeZone']);
    const safeZones = resources.setup_safeZone;

    const canvasRef = useRef<HTMLCanvasElement>(null);

    const { showSafeZones, formatSafeZoneLayout, generalSafeZoneLayout, formatSafeZoneColor, generalSafeZoneColor, type } = useComponentStore<Data>(
        'TemplateDesigner',
        {
            fields: {
                showSafeZones: 'view.showSafeZones',
                formatSafeZoneLayout: `layerProperties.${format.key}.${frameType}.properties.safeZone.activeLayout`,
                generalSafeZoneLayout: `layerProperties.general.${frameType}.properties.safeZone.activeLayout`,
                formatSafeZoneColor: `layerProperties.${format.key}.${frameType}.properties.safeZone.color`,
                generalSafeZoneColor: `layerProperties.general.${frameType}.properties.safeZone.color`,
                type: 'templateData.type'
            }
        }
    );

    const safeZone: SafeZoneType | undefined = safeZones?.[type]?.[formatSafeZoneLayout || generalSafeZoneLayout];
    const color = formatSafeZoneColor || generalSafeZoneColor || defaultSafeZone.color;

    const isCorrectRatio = safeZone && safeZone.formatWidth / safeZone.formatHeight === format.width / format.height;

    /**
     * Draw the safe zone on the canvas.
     */
    useEffect(() => {
        if (!canvasRef.current || !isCorrectRatio) return;

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.clearRect(0, 0, safeZone.formatWidth * SAFE_ZONE_RESOLUTION_SCALING, safeZone.formatHeight * SAFE_ZONE_RESOLUTION_SCALING);

        ctx.fillStyle = color.hex;

        ctx.fillRect(0, 0, safeZone.left * SAFE_ZONE_RESOLUTION_SCALING, safeZone.formatHeight * SAFE_ZONE_RESOLUTION_SCALING);
        ctx.fillRect(
            (safeZone.formatWidth - safeZone.right) * SAFE_ZONE_RESOLUTION_SCALING,
            0,
            safeZone.right * SAFE_ZONE_RESOLUTION_SCALING,
            safeZone.formatHeight * SAFE_ZONE_RESOLUTION_SCALING
        );
        ctx.fillRect(
            safeZone.left * SAFE_ZONE_RESOLUTION_SCALING,
            0,
            (safeZone.formatWidth - safeZone.left - safeZone.right) * SAFE_ZONE_RESOLUTION_SCALING,
            safeZone.top * SAFE_ZONE_RESOLUTION_SCALING
        );
        ctx.fillRect(
            safeZone.left * SAFE_ZONE_RESOLUTION_SCALING,
            (safeZone.formatHeight - safeZone.bottom) * SAFE_ZONE_RESOLUTION_SCALING,
            (safeZone.formatWidth - safeZone.left - safeZone.right) * SAFE_ZONE_RESOLUTION_SCALING,
            safeZone.bottom * SAFE_ZONE_RESOLUTION_SCALING
        );

        safeZone.rectangles?.forEach((square) => {
            ctx.fillRect(
                square.left * SAFE_ZONE_RESOLUTION_SCALING,
                square.top * SAFE_ZONE_RESOLUTION_SCALING,
                (safeZone.formatWidth - square.right - square.left) * SAFE_ZONE_RESOLUTION_SCALING,
                (safeZone.formatHeight - square.bottom - square.top) * SAFE_ZONE_RESOLUTION_SCALING
            );
        });
    }, [safeZone, color, isCorrectRatio]);

    if (!isCorrectRatio) return null;

    return (
        <canvas
            ref={canvasRef}
            style={{ display: showSafeZones ? 'block' : 'none' }}
            className="template-designer__safe-zone"
            width={safeZone.formatWidth * SAFE_ZONE_RESOLUTION_SCALING}
            height={safeZone.formatHeight * SAFE_ZONE_RESOLUTION_SCALING}
        />
    );
};

export { SafeZone };
